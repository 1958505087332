const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
    componentPath:'/Home/index.tsx'
  },{
    path: ["/login"],
    exact: true,
    component: "LoginPage",
    componentPath:'/Login/index.jsx'
  },
  {
    path: ["/policy"],
    exact: true,
    component: "Policy",
    componentPath:'/Extra/policy.jsx'
  },
  {
    path: ["/code-conduct"],
    exact: true,
    component: "Conduct",
    componentPath:'/Extra/Conduct.jsx'
  },
  {
    path: ["/faq"],
    exact: true,
    component: "FAQ",
    componentPath:'/Extra/FAQ.jsx'
  },
  {
    path: ["/rules"],
    exact: true,
    component: "Rules",
    componentPath:'/Extra/Rules.jsx'
  },
  {
    path: ["/admin/dashboard"],
    exact: true,
    component: "Dashboard",
    componentPath:'/Admin/index.js'
  },
];

export default routes;
